import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import BrandOne from "../elements/Brand";
// import BrandTwo from "../elements/BrandTwo";
// import BlogContent from "../elements/blog/BlogContent";
import TabOne from "../elements/tab/TabOne";
// import PortfolioList from "../elements/portfolio/PortfolioList";
import TextLoop from "react-text-loop";
// import ServiceList from "../elements/service/ServiceList";
// import { Link } from "react-router-dom";
const SlideList = [
  {
    textPosition: "text-left",
    category: "Welcome To my world",
    title: "Hello, I’m <span>Dave Degeatano.</span>",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popula",
    buttonText: "",
    buttonLink: "",
  },
];
class PersonalPortfolio extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    // const PostList = BlogContent.slice(0, 3);

    let title = "My About",
      description =
        "I love using creativity to solve a variety of complex technical challenges. I've always been fascinated by software and the ability to code anything imaginable. I've worked in various technical environments and always loved using code to solve problems and make life easier.";

        
    return (
      <Fragment>
        {/* <Helmet pageTitle="Personal Portfolio" /> */}
        <HeaderThree logo="symbol-dark" color="color-black" />

        {/* Start Slider Area   */}
        <div id="home" className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide slider_fixed_height slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--16 white-overlay-mobile"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className="title">
                        I’m Dave Degeatano <br />
                        <TextLoop>
                          <span> Software Engineer.</span>
                          {/* <span> JS Developer.</span> */}
                        </TextLoop>{" "}
                      </h1>
                      {/* <h2>based in USA.</h2> */}
                      {/* {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )} */}
                      {value.buttonText ? (
                        <div className="slide-btn mt--30">
                          <a
                            className="btn-default btn-border btn-opacity"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* End Slider Area   */}
        <div id="about" className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-3.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      {/* <span className="subtitle">Our company about</span> */}
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                      <p className="description">In my 15+ years working in the industry, I've played a wide variety of roles.  I love working with people as much as I love writing code and my mission is to always combine these two passions together to deliver top quality software solutions, made by and for amazing people.</p>
                    </div>
                    <div className="row mt--30">
                      <TabOne tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start Brand Area */}
        <div id="companies" className="rn-brand-area bg_color--1 ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="section-title text-center mb--30">
                            {/* <span className="subtitle">Work history</span> */}
                            <h2 className="title">I've worked with Top companies</h2>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-lg-12">
                        <BrandTwo />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-lg-12">
                        <BrandOne branstyle="branstyle--2" />
                    </div>
                </div>
            </div>
        </div>
        {/* End Brand Area */}


        {/* Start Brand Area  */}
        {/* <div className="rn-brand-area ptb--120 bg_color--5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <BrandOne branstyle="branstyle--2" />
                    </div>
                </div>
            </div>
        </div> */}
        {/* End Brand Area  */}

        {/* Start Service Area  */}
        {/* <div id="service" className="service-area  pb--50 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <span className="subtitle">What I can do for you</span>
                  <h2 className="title">My Awesome Service</h2>
                  <p className="description">
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <ServiceList
              item="6"
              column="col-lg-4 col-md-6 col-sm-6 col-12 text-center"
            />
          </div>
        </div> */}
        {/* End Service Area  */}

        {/* Start Portfolio Area */}
        {/* <div id="projects" className="portfolio-area pb--60 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30 mb_sm--0">
                    <span className="subtitle">Our project</span>
                    <h2 className="title">Some of My Recent Works</h2>
                    <p className="description">
                      There are many variations of passages of Lorem Ipsum
                      available, <br /> but the majority have suffered
                      alteration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 mt_sm--30 text-center">
                    <a className="btn-default" href="/#">
                      <span>View Projects</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Portfolio Area */}
        {/* Start Blog Area */}
        {/* <div id="blog" className="rn-blog-area  pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3">
                  <span className="subtitle">Latest post</span>
                  <h2 className="title">Latest News</h2>
                  <p className="description">
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--30">
              {PostList.map((value, i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                  key={i}
                >
                  <div className="im_box">
                    <div className="thumbnail">
                      <Link to="#">
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <div className="content_heading">
                          <div className="category_list">
                            <Link to="/portfolio-details">
                              {value.category}
                            </Link>
                          </div>
                          <h4 className="title">
                            <Link to="#">{value.title}</Link>
                          </h4>
                        </div>
                        <div className="content_footer">
                          <Link to="#" className="rn-btn btn-opacity">
                            Read More
                          </Link>
                        </div>
                      </div>
                      <Link className="transparent_link" to="#"></Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a className="btn-default" href="/blog">
                    <span>View More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Blog Area */}

        <FooterTwo />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default PersonalPortfolio;
