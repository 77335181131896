import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <a href="https://www.linkedin.com/company/intuit" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/brand/intuit.jpeg" alt="Intuit"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/atlassian" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/brand/atlassian.jpeg" alt="Atlassian"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/13645438" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/brand/slash.jpeg" alt="Slash"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/axa" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/brand/axa.jpeg" alt="AXA"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/163460" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/brand/digital_insight.jpeg" alt="Digital Insight"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/1235" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/brand/wells_fargo.jpeg" alt="Wells Fargo"/>
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;