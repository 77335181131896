import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaCircle } from "react-icons/fa";

class TabsOne extends Component {
  render() {
    let tab1 = "Main Skills",
      tab5 = "Other",
      tab2 = "Awards",
      tab3 = "Experience",
      tab4 = "Education";
    const { tabStyle } = this.props;
    var namesItemOne = [
      "Javascript, React, Node, Express",
      "MongoDB, Postgres, RESTful APIs",
      "AWS, Google Cloud, Firebase, Linux",
      "Past experience with: Perl, Python, PHP, Java, shell scripting",
    ];
    var otherSkills = ["Collaboration and Teamwork", "Communication", "Leadership", "Agile Project Management"];
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab5}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <div className="mt--10 mt_lg--10 mt_md--10 mt_sm--10">
                        {/* <h4>Lorem ipsum dolor sit.</h4> */}
                        <ul className="list-style--1">
                          {namesItemOne.map((name, index) => {
                            return (
                              <li key={index}>
                                <FaCircle /> {name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="single-tab-content">
                      <div className="mt--10 mt_lg--10 mt_md--10 mt_sm--10">
                        {/* <h4>Lorem ipsum dolor sit.</h4> */}
                        <ul className="list-style--1">
                          {otherSkills.map((name, index) => {
                            return (
                              <li key={index}>
                                <FaCircle /> {name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="single-tab-content">
                      <h5>Analyst of the Year Award</h5>
                      <h6>Intuit</h6>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="single-tab-content"
                    >
                      <h4>Slash.co</h4>
                      <h5>Full Stack Software Engineer</h5>
                      <h6>Mar 2019 - Present</h6>
                      <ul>
                        <li>
                          - Develop full-stack web applications in Javascript
                          mainly using Node and React
                        </li>
                        <li>- Team leadership</li>
                        <li>- Project management</li>
                      </ul>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="single-tab-content"
                    >
                      <h4>Davey Dee</h4>
                      <h5>Freelance Developer</h5>
                      <h6>Mar 2018 - Feb 2022</h6>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="single-tab-content"
                    >
                      <h4>Atlassian</h4>
                      <h5>Senior Support Engineer</h5>
                      <h6>Jan 2017 - Mar 2018</h6>
                      <p>
                        As a Support Engineer, I love helping our customer
                        organizations succeed in their businesses by helping
                        them install, configure, and maintain Atlassian products
                        and services. I bring years of experience and expertise
                        in a variety of technical areas not only to make sure
                        our systems are up and running in top shape, but also
                        providing continuous support and consulting and planning
                        for future growth.{" "}
                      </p>
                      <p className="mb-3">
                        Beyond the technical, I take great pride in being an
                        outstanding team player and always putting my team and
                        my customers first. My teams and customers will agree
                        that you'll find me thriving in a socially active and
                        technically challenging environment, working anywhere
                        from development to delivery and support - following my
                        passions, always learning and always collaborating.{" "}
                      </p>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="single-tab-content pb-3"
                    >
                      <h4>Intuit</h4>
                      <h5>Senior Technical Support Engineer</h5>
                      <h6>Jun 2006 - Nov 2010 </h6>
                      <p>
                        I work on teams of Developers, QA, Systems Engineers,
                        DevOps, Project Managers, etc. during all phases of
                        product development lifecycle - from development,
                        testing, deployment, and support
                      </p>
                      <h5>Senior Customer Support Analyst</h5>
                      <h6>Nov 2010 - Aug 2014</h6>
                      <p>
                        Problem analysis, troubleshooting, and resolution to a
                        wide variety of products and services. Assisted in
                        client product installations, modifications,
                        customizations, and problem resolution
                      </p>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="single-tab-content pb-3"
                    >
                      <h4>Digital Insight</h4>
                      <h5>Growth and Retention Specialist</h5>
                      <h6>Mar 2006 - Jun 2006</h6>
                      <p>
                        Worked directly with many high profile clients to review
                        and analyze their Internet Banking platforms to help
                        uncover their best options for targeted marketing
                        campaigns designed to help their existing internet
                        banking users adopt additional product offerings and
                        services such as bill payment and online payment
                        services.{" "}
                      </p>
                    </div>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="single-tab-content pb-3"
                    >
                      <h4>Teller</h4>
                      <h5>Wells Fargo Bank</h5>
                      <h6>May 2004 - Sep 2005</h6>
                      <p>
                        Customer facing position, assisting with daily
                        transactions for both personal and business clients. In
                        addition, I used my naturally strong communication and
                        interpersonal skills to assist new and existing clients.
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="single-tab-content pb-3"
                    >
                      <h4>California Lutheran University</h4>
                      <h5>Bachelor's Degree</h5>
                      <h5>
                        Computer Science, Information
                        Systems, Business
                      </h5>
                      <h6>2008 - 2011</h6>
                      <p>
                        Software Engineering, Systems Analysis, Computer
                        Programming, Database Management, Operating Systems,
                        System Security
                      </p>
                    </div>
                    <div className="single-tab-content pb-3">
                      <h4>Moorpark College</h4>
                      <h5>Associate's Degree</h5>
                      <h5>
                        Liberal Arts and Sciences/Liberal
                        Studies
                      </h5>
                      <h6>2002 - 2008</h6>
                      <p>
                        Computer Science, Information Technology, Sociology,
                        Psychology, Photography, Communications
                      </p>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}
export default TabsOne;
